import {useTranslation} from "react-i18next"
import {useHistory} from "react-router"

import menuExamsImg from "../../../../../../assets/images/menu/menu-exams.png"
import menuPrescriptionImg from "../../../../../../assets/images/menu/menu-prescription.png"
import menuRefferalImg from "../../../../../../assets/images/menu/menu-refferal.png"
import menuRemoteConsultationImg from "../../../../../../assets/images/menu/menu-remote-consultation.png"
import menuSickLeaveImg from "../../../../../../assets/images/menu/menu-sick-leave.png"
import menuStationaryVisitImg from "../../../../../../assets/images/menu/menu-stationary-visit.png"
import useGoToConsultationPage from "../../../../../../hooks/useGoToConsultationPage"
import {LogEventType} from "../../../../../../services/analytics"
import {checkFeatureAvailability} from "../../../../../../store/clinic/clinicSettings.utils"
import {RoutePath} from "../../../../../../routes/Routes.types"
import {
  ConsultationWidgetVisitDatePicker
} from "../../../../../../store/makeConsultationWidget/makeConsultationWidget.types"
import {
  MakeConsultationMenuItemLabel,
  MakeConsultationMenuItemValue,
} from "./MakeConsultationMenuItems.types"

const useMakeConsultationMenuListItems = () => {
  const { i18n } = useTranslation()
  const { push } = useHistory()

  const goToSickLeaveOnlyConsultationPage = useGoToConsultationPage({
    visitDatePicker: ConsultationWidgetVisitDatePicker.SICK_LEAVE_ONLY
  })

  const goToRemoteConsultationPage = useGoToConsultationPage({})
  const goToReferralConsultationPage = useGoToConsultationPage({})

  const goToStationaryPozConsultationPage = useGoToConsultationPage({
    visitDatePicker: ConsultationWidgetVisitDatePicker.STATIONARY_VISIT_DATA_PICKER
  })

  const goToPrescriptionOnlyConsultationPage = useGoToConsultationPage({
    visitDatePicker: ConsultationWidgetVisitDatePicker.PRESCRIPTION_ONLY,
  })

  const goToNewPrescriptionConsbookWidgetPage = useGoToConsultationPage({
    isNewPrescriptionConsbookWidget: checkFeatureAvailability().enabledNewPrescriptionConsbookWidget
  })
  
  const makeList = ()=>{
    return [
      {
        text: MakeConsultationMenuItemValue.PRESCRIPTION,
        additionalText: checkFeatureAvailability().enabledNewPrescriptionConsbookWidget 
          ? MakeConsultationMenuItemLabel.NEW_PRESCRIPTION_CONSBOOK_WIDGET
          : MakeConsultationMenuItemLabel.PRESCRIPTION_LABEL,
        value: MakeConsultationMenuItemValue.PRESCRIPTION,
        B2BClinicVisibility: true,
        featureEnabled: checkFeatureAvailability().prescription,
        image: menuPrescriptionImg,
        imagePath: "menu/menu-prescription.png",
        onClickHandler: checkFeatureAvailability().enabledNewPrescriptionConsbookWidget 
          ? goToNewPrescriptionConsbookWidgetPage.goToMakeConsultationPage
          : goToPrescriptionOnlyConsultationPage.goToMakeConsultationPage,
        googleAnalyticsEvent: LogEventType.DASHBOARD_MENU_ORDER_PRESCRIPTION_CLICK
      },
      {
        text: MakeConsultationMenuItemValue.REMOTE_CONSULTATION,
        additionalText: MakeConsultationMenuItemLabel.REMOTE_CONSULTATION_LABEL,
        value: MakeConsultationMenuItemValue.REMOTE_CONSULTATION,
        B2BClinicVisibility: true,
        featureEnabled: checkFeatureAvailability().remoteConsultation,
        image: menuRemoteConsultationImg,
        imagePath: "menu/menu-remote-consultation.png",
        onClickHandler: goToRemoteConsultationPage.goToMakeConsultationPage,
        googleAnalyticsEvent: LogEventType.DASHBOARD_MENU_ORDER_REMOTE_CONSULTATION_CLICK,
      },
      {
        text: MakeConsultationMenuItemValue.EMAIL_STATIONARY_CONSULTATION,
        additionalText: MakeConsultationMenuItemLabel.EMAIL_STATIONARY_CONSULTATION_LABEL,
        value: MakeConsultationMenuItemValue.EMAIL_STATIONARY_CONSULTATION,
        B2BClinicVisibility: true,
        featureEnabled: checkFeatureAvailability().emailStationaryVisits,
        image: menuStationaryVisitImg,
        imagePath: "menu/menu-stationary-visit.png",
        onClickHandler: () => {
          push(`/${i18n.language}${RoutePath.EMAIL_STATIONARY_CONSULTATION}`)
        },
        googleAnalyticsEvent: LogEventType.DASHBOARD_MENU_ORDER_STATIONARY_CONSULTATION_CLICK,
      },
      {
        text: MakeConsultationMenuItemValue.STATIONARY_CONSULTATION,
        additionalText: MakeConsultationMenuItemLabel.STATIONARY_CONSULTATION_LABEL,
        value: MakeConsultationMenuItemValue.STATIONARY_CONSULTATION,
        B2BClinicVisibility: true,
        featureEnabled: checkFeatureAvailability().stationaryVisits,
        image: menuStationaryVisitImg,
        imagePath: "menu/menu-stationary-visit.png",
        onClickHandler: () => {
          push(`/${i18n.language}/make-stationary-consultation`)
        },
        googleAnalyticsEvent: LogEventType.DASHBOARD_MENU_ORDER_STATIONARY_CONSULTATION_CLICK,
      },
      // LESS-2115 - Ukrycie w menu bocznym przedłużenie recepty
      // {
      //   text: MakeConsultationMenuItemValue.PRESCRIPTION_EXTENSION,
      //   additionalText: MakeConsultationMenuItemLabel.PRESCRIPTION_EXTENSION_LABEL,
      //   value: MakeConsultationMenuItemValue.PRESCRIPTION_EXTENSION,
      //   B2BClinicVisibility: true,
      //   featureEnabled: checkFeatureAvailability().prescription,
      //   image: menuPrescriptionExtensionImg,
      // },
      {
        text: MakeConsultationMenuItemValue.SICK_LEAVE,
        additionalText: MakeConsultationMenuItemLabel.SICK_LEAVE_LABEL,
        value: MakeConsultationMenuItemValue.SICK_LEAVE,
        B2BClinicVisibility: true,
        featureEnabled: checkFeatureAvailability().sickLeave,
        image: menuSickLeaveImg,
        imagePath: "menu/menu-sick-leave.png",
        onClickHandler: goToSickLeaveOnlyConsultationPage.goToMakeConsultationPage,
        googleAnalyticsEvent: LogEventType.DASHBOARD_MENU_ORDER_SICK_LEAVE_CLICK,
      },
      {
        text: MakeConsultationMenuItemValue.MEDICAL_EXAMINATION,
        additionalText: MakeConsultationMenuItemLabel.MEDICAL_EXAMINATION_LABEL,
        value: MakeConsultationMenuItemValue.MEDICAL_EXAMINATION,
        B2BClinicVisibility: true,
        featureEnabled: checkFeatureAvailability().exams,
        image: menuExamsImg,
        imagePath: "menu/menu-exams.png",
        onClickHandler: () => {
          push(`/${i18n.language}/order-exams`)
        },
        googleAnalyticsEvent: LogEventType.DASHBOARD_MENU_ORDER_EXAMINATION_CLICK,
      },
      {
        text: MakeConsultationMenuItemValue.REFERRAL,
        additionalText: MakeConsultationMenuItemLabel.REFERRAL_LABEL,
        value: MakeConsultationMenuItemValue.REFERRAL,
        B2BClinicVisibility: true,
        featureEnabled: !checkFeatureAvailability().disableRefferals,
        image: menuRefferalImg,
        imagePath: "menu/menu-refferal.png",
        onClickHandler: goToReferralConsultationPage.goToMakeConsultationPage,
        googleAnalyticsEvent: LogEventType.DASHBOARD_MENU_ORDER_REFERRAL_CLICK,
      },
      {
        text: MakeConsultationMenuItemValue.ON_SITE_POZ_CONSULTATION,
        value: MakeConsultationMenuItemValue.ON_SITE_POZ_CONSULTATION,
        additionalText: "",
        B2BClinicVisibility: false,
        featureEnabled: checkFeatureAvailability().pozformB2BActive,
        image: menuStationaryVisitImg,
        imagePath: "menu/menu-stationary-visit.png",
        onClickHandler: goToStationaryPozConsultationPage.goToMakeConsultationPage,
        googleAnalyticsEvent: LogEventType.DASHBOARD_MENU_ORDER_STATIONARY_POZ_CONSULTATION_CLICK,
      },
    ]
  }

  return {
    makeList
  }
}
export default useMakeConsultationMenuListItems
